<template>
  <div id="wrapper" class="app">
    <Nav />

    <div class="title pt-0 mt-0">
      <img class="background-image" src="@/assets/img/background.jpeg" alt="" />

      <router-link :to="{ name: 'home' }" class="centered-content">
        <h1 class="title-outlined-text text-center text-md-start">
          Aero Estate
        </h1>

        <h5
          class="sub-title-outlined-text text-center text-md-start h-100"
          style="text-align: center !important"
        >
          Aeroplane hangers, engineering shops and accommodation
        </h5>
        <img class="plane-image vw-25" src="@/assets/img/cesna.png" alt="" />
      </router-link>
    </div>

    <div class="page container-fluid">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
          <div class="row">
            <div class="col-12 mb-2">
              <div style="padding: 56.25% 0 0 0; position: relative">
                <iframe
                  src="https://player.vimeo.com/video/914392141?badge=0&player_id=0&autoplay=1&loop=1&app_id=58479&mute=1"
                  frameborder="0"
                  allow="autoplay; fullscreen picture-in-picture"
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                  "
                  title="Site_Movie_2023_10_05"
                  autoplay
                ></iframe>
              </div>
            </div>
            <div class="col-12">
              <Carousel v-if="galleryImages" :gallery="galleryImages" height="auto" id="galleryCarousel" />
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
          <NuxtPage class="mb-2" />
        </div>
      </div>
    </div>
  </div>

  <div v-if="runTimeConfig.public.isLoading">
    <Spinner />
  </div>
</template>

<script setup>
let runTimeConfig = useRuntimeConfig();
const galleryImages = Object.values(
  import.meta.glob(`/assets/img/gallery/*.*`, {
    eager: true,
    query: '?url', import: 'default'
  })
);

</script>

<style scoped>
.page {
  color: black;
}

.header {
  background-color: rgb(81, 104, 141, 0.4) !important;
  z-index: 1 !important;
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the alpha value to control the transparency of the overlay */
  z-index: -1;
}
.plane-image {
  position: absolute;
  top: 0px;
  right: 30px;
  transform: scale(1.3);
  width: 30vw;
  z-index: -1;
}

h1 {
  font-size: 75px;
}
h5 {
  font-size: 35px;
}

a {
  text-decoration: none;
}
</style>
